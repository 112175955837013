s<template>
  <div class="page-b2b-order-detail">
    <div class="loading" v-if="loading">
      <a-spin size="large"></a-spin>
    </div>
    <template v-else>
      <div class="mod-group">
        <div class="cell-header">
          <h4>订单基础信息</h4>
        </div>
        <div class="cell-body">
          <table class="table-infomation">
            <tr>
              <th>提交人:</th>
              <td>{{ data.submitter }}</td>
              <th>提交人账户:</th>
              <td>{{ data.submitAccount }}</td>
              <th>联系电话:</th>
              <td>{{ data.contact.contactMobile }}</td>
              <th>订单类型:</th>
              <td>{{ data.orderType | orderType }}</td>
            </tr>
            <tr>
              <th>订单编号:</th>
              <td>{{ data.orderNo }}</td>
              <th>订单状态:</th>
              <td>{{ data.statusName }}</td>
              <th>成交人数:</th>
              <td>{{ data.leftGroupQty }}</td>
              <th>拆分类型:</th>
              <td>{{
                  data.orderId === data.parentOrderId || !data.parentOrderId
                    ? "主订单"
                    : "子订单"
                }}</td>
            </tr>
            <tr>
              <th>应付总金额:</th>
              <td>{{ data.statisticInfo && data.statisticInfo.totalOrderAmount  }}</td>
              <th>已付总金额:</th>
              <td>{{ data.statisticInfo && data.statisticInfo.totalActualAmount }}</td>
            </tr>
          </table>
        </div>
      </div>
      <detail-diff-apply-info
        v-if="misPayRecord.misPayRecord"
        :list="[misPayRecord.misPayRecord]"
      >
        <div style="margin-top: 16px" slot="header">
          <span>差错申请单号: {{ misPayRecord.misPayNo }}</span>
          <span style="margin-left: 100px; color: #f00"
            >差错申请时间: {{ misPayRecord.misPayRecord && misPayRecord.misPayRecord.creationTimestamp }}</span
          >
          <span style="margin-left: 100px; color: #f00"
            >申请人: {{ misPayRecord.submitName }}</span
          >
          <span style="margin-left: 100px; color: #f00"
            >申请单状态: {{ misPayRecord.orderStatus | status }}</span
          >
        </div>
        <div style="margin-top: 16px" slot="footer">
          <a-row>
            <a-col :span="12">申请原因: {{misPayRecord.misPayRecord.remark}}</a-col>
            <a-col :span="12">
              材料附件：
              <template v-if="misPayRecord.misPayRecord.urls && misPayRecord.misPayRecord.urls.length">
                <a
                  v-for="item in misPayRecord.misPayRecord.urls"
                  :key="item.filePath"
                  :href="item.filePath"
                  target="_blank"
                  :style="{marginLeft: '10px'}"
                  >{{ item.fileName }}</a
                >
              </template>
            </a-col>
          </a-row>
        </div>
      </detail-diff-apply-info>
      <detail-diff-refund-info
        :list="misPayRecord.misPayRefundInfos"
      >
      </detail-diff-refund-info>
    </template>
  </div>
</template>

<script>
import queryString from 'query-string'
import { fetchDifferRefundDetail } from '@/api/order'
import DetailDiffApplyInfo from './components/detail-diff-apply-info'
import DetailDiffRefundInfo from './components/detail-diff-refund-info'
import { format } from '@/filters/time'
import { status, orderType } from '@/filters/order'
import './css/detail.less'

export default {
  components: {
    DetailDiffApplyInfo,
    DetailDiffRefundInfo
  },
  data () {
    return {
      data: {},
      misPayRecord: {},
      audits: null,
      visible: false,
      type: '',
      confirmLoading: false,
      loading: false
    }
  },
  computed: {
    agentId () {
      const userData = this.$store.state.user.userData
      return userData && userData.agentId
    }
  },
  created () {
    const params = queryString.parse(location.search)
    this.fetchDetail(params.misPayNo, this.agentId)
  },
  methods: {
    async fetchDetail (misPayNo, agentId) {
      this.loading = true

      try {
        const data = await fetchDifferRefundDetail({
          misPayNo: misPayNo,
          agentId: agentId
        })

        this.data = data.bizData
        this.audits = data.auditHis
        this.misPayRecord = data.bizData.misPayRecordDetailDTOS.length ? data.bizData.misPayRecordDetailDTOS[0] : {}
        this.loading = false
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    async handleOk () {
      const { type } = this

      try {
        this.confirmLoading = true
        await this.$refs[type].submit()
        this.confirmLoading = false
      } catch (err) {
        this.confirmLoading = false
      }
    }
  },
  filters: {
    format,
    status,
    orderType
  }
}
</script>
