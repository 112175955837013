<template>
  <div class="mod-group mod-base">
    <div class="cell-header">
      <h4>差错退款信息</h4>
    </div>
    <div class="cell-body">
      <slot></slot>
      <a-table
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
        </span>
        <span slot="refundMethod" slot-scope="text">
          {{ text | refundMethod }}
        </span>
        <span slot="refundStatus" slot-scope="text">
          {{ text | refundStatus }}
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { refundPurpose as refundPurposes } from '@/dicts/order'
import { refundStatus, refundPurpose } from '@/filters/order'
import { bank, status as payStatus } from '@/filters/pay'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '支付流水号',
          dataIndex: 'tradeNo'
        },
        {
          title: '退款流水',
          dataIndex: 'refundTradeNo'
        },
        {
          title: '退款方式',
          dataIndex: 'refundMethod',
          scopedSlots: { customRender: 'refundMethod' }
        },
        {
          title: '支付金额',
          dataIndex: 'refundAmount'
        },
        {
          title: '退款时间',
          dataIndex: 'refundTime'
        },
        {
          title: '退款状态',
          dataIndex: 'refundStatus',
          scopedSlots: { customRender: 'refundStatus' }
        }

      ],
      refundPurposes
    }
  },
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      }
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange (col, value, index) {
      this.$emit('change', col, value, index)
    }
  },
  filters: {
    bank,
    payStatus,
    refundStatus,
    refundPurpose,
    refundMethod (val) {
      const refundMethod = {
        on: '线上',
        off: '线下'
      }

      return refundMethod[val]
    }
  }
}
</script>
